.ant-card {
  border: 0 !important;
  box-shadow: none !important;
}

.ant-btn.ant-btn-link[class*=' ant-btn'] {
  &:hover {
    color: inherit;
  }
}

.ant-tour {
  .ant-tour-arrow {
    &::before {
      background: #1677ff !important;
    }

    &::after {
      background: #1677ff;
    }
  }

  .ant-tour-inner {
    background-color: #1677ff !important;
  }

  .ant-tour-buttons {
    button {
      color: #1677ff !important;
    }
  }
}

.ant-dropdown {
  .ant-dropdown-menu-title-content {
    width: 100%;
  }
}

.ant-carousel {
  .slick-slider {
    .slick-dots {
      li {
        width: 6px !important;
        height: 6px !important;

        button {
          width: 6px !important;
          height: 6px !important;
          border-radius: 50% !important;
          background-color: #333 !important;
        }
      }

      .slick-active {
        button {
          background-color: #37aeb9 !important;
        }
      }
    }
  }
}

.ant-tabs-no-border {
  .ant-tabs-nav::before {
    display: none !important;
  }
}

.ant-message {
  z-index: 10000 !important;
}

.ant-table-wrapper {
  .ant-table {
    .ant-table-thead {
      tr {
        .ant-table-cell {
          background: #f9fcff;
        }
      }
    }

    .ant-table-tbody > tr > td.ant-table-cell.ant-table-cell-row-hover {
      background: #f9fcff;
    }

    .ant-table-expanded-row {
      .ant-table-cell {
        background: #fff !important;
      }
    }
  }
}
