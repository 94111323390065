.icon-import {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: transparent url('assets/workbench/icon-import.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-upload {
  display: inline-block;
  width: 81px;
  height: 72px;
  background: transparent url('assets/workbench/icon-upload.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-analysis {
  display: inline-block;
  width: 120px;
  height: 120px;
  background: transparent url('assets/workbench/icon-analysis.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-setting {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url('assets/workbench/icon-setting.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-setting2 {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url('assets/workbench/icon-setting2.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-add-group {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url('assets/workbench/icon-add-group.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-filled-down {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: transparent url('assets/workbench/icon-filled-down.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-scores {
  display: inline-block;
  width: 28px;
  height: 28px;
  background: transparent url('assets/workbench/icon-scores.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-student-details {
  display: inline-block;
  width: 28px;
  height: 28px;
  background: transparent url('assets/workbench/icon-student-details.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-view-detail {
  display: inline-block;
  width: 28px;
  height: 28px;
  background: transparent url('assets/workbench/icon-view-detail.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-phone {
  display: inline-block;
  width: 15px;
  height: 18px;
  background: transparent url('assets/index/icon-phone.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-empty {
  display: inline-block;
  width: 100px;
  height: 75px;
  background: transparent url('assets/workbench/empty.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-helper {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url('assets/icon-helper.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-help {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url('./assets/icons/icon-help.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-service {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url('./assets/icons/icon-service.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-comment {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url('./assets/icons/icon-comment.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-doc {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url('./assets/icons/icon-doc.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-invite {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: transparent url('assets/icons/icon-invite.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-edit-primary {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url('./assets/icons/icon-edit-primary.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-edit {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url('./assets/icons/icon-edit.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-edit-with-hover {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url('./assets/icons/icon-edit.png') 0 0 no-repeat;
  background-size: 100%;
  cursor: pointer;

  &:hover {
    background: transparent url('./assets/icons/icon-edit-primary.png') 0 0 no-repeat;
    background-size: 100%;
  }
}

.icon-delete-primary {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url('./assets/icons/icon-delete-primary.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-delete-width-hover-red {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url('./assets/icons/icon-delete.png') 0 0 no-repeat;
  background-size: 100%;
  cursor: pointer;

  &:hover {
    background: transparent url('./assets/icons/icon-delete-red.png') 0 0 no-repeat;
    background-size: 100%;
  }
}

.icon-normal-user {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: transparent url('assets/icons/icon-normal-user.png') 0 0 no-repeat;
  background-size: 100%;
  cursor: pointer;
}

.icon-vip-user {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: transparent url('assets/icons/icon-vip-user.png') 0 0 no-repeat;
  background-size: 100%;
  cursor: pointer;
}

.icon-svip-user {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: transparent url('assets/icons/icon-svip-user.png') 0 0 no-repeat;
  background-size: 100%;
  cursor: pointer;
}

.icon-schoolvip-user {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: transparent url('assets/icons/img-school-vip-logo.png') 0 0 no-repeat;
  background-size: 100%;
  cursor: pointer;
}

.icon-person-phone {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url('assets/icons/icon-person-phone.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-vip {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url('assets/icons/icon-vip.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-person-school {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url('assets/icons/icon-person-school.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-person-location {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url('assets/icons/icon-person-location.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-export-grade {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: transparent url('assets/icons/icon-export-grade.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-export-class {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: transparent url('assets/icons/icon-export-class.png') 0 0 no-repeat;
  background-size: 100%;
}

.icon-export-subject {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: transparent url('assets/icons/icon-export-subject.png') 0 0 no-repeat;
  background-size: 100%;
}
