@import './reset';
@import './table';
@import './antd-fix';
@import './antd-utils';
@import './icons';

@font-face {
  font-family: hm; // 自定义名称，无需引号
  src: url('./HarmonyOS_Sans_SC_Regular.ttf'); // 字体文件路径，支持本地文件，或字体文件链接地址
}

@font-face {
  font-family: handwritingRegular; // 自定义名称，无需引号
  src: url('./handwriting-Regular.ttf'); // 字体文件路径，支持本地文件，或字体文件链接地址
}

a {
  color: #37aeb9;
  cursor: pointer !important;
}

.hover-show-target {
  display: none !important;
}

.hover-show:hover .hover-show-target {
  display: block !important;
}

.hover-show-inline:hover .hover-show-target {
  display: inline-block !important;
}

.hover-show-flex:hover .hover-show-target {
  display: flex !important;
}

.link {
  color: #37aeb9;

  &:hover {
    text-decoration: underline;
  }
}

.ellipsis1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hover-color-primary:hover {
  cursor: pointer;
  color: #009daa !important;
}

.hover-underline:hover {
  cursor: pointer;
  text-decoration: underline;
}

.hover-color-blue:hover {
  cursor: pointer;
  color: #165dff !important;
}

.headlineTableStyle {
  .ant-table {
    .ant-table-thead {
      .ant-table-cell {
        background: #f8f8f8;
        font-weight: 400;
      }
    }
  }
}

.exportReportTableStyle {
  .ant-table {
    .ant-table-thead {
      .ant-table-cell {
        background: #f7f8fa;
        color: #86909c;
        font-weight: 400;
        font-size: 12px;
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        font-size: 12px;
      }
    }
  }
}

.exportReportFontStyle,
.tableFontHm {
  .ant-table {
    font-family: hm !important;
  }
}

.exportReportTableLastLineBorder {
  .ant-table {
    .ant-table-tbody {
      tr:last-child {
        font-weight: 600 !important;
      }
    }
  }
}

.exportReportTableLessPadding {
  .ant-table {
    .ant-table-thead > tr > th {
      padding: 8px 4px !important;
    }

    .ant-table-tbody > tr > td {
      padding: 8px 4px !important;
    }
  }
}

.modal-transparent {
  &.ant-modal-wrap .ant-modal-content {
    background: transparent;
    box-shadow: none;
  }
}

.custom-scrollbar,
.tableCustomScrollbar .ant-table-body {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(0 0 0 / 20%);
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgb(0 0 0 / 5%);
  }
}

.tableHeaderVerticalAlignTop {
  .ant-table-cell {
    vertical-align: top !important;
  }
}

.tableLessCustomPadding4px {
  .ant-table {
    .ant-table-thead > tr > th {
      padding: 4px !important;
      font-size: 10px;
    }

    .ant-table-tbody > tr > td {
      padding: 4px !important;
      font-size: 10px;
    }
  }
}

.tableLessCustomPadding0px {
  .ant-table {
    .ant-table-tbody > tr > td {
      padding: 0 !important;
    }
  }
}

.icon-qywx {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-qywx.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-weixin {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: transparent url("assets/icons/icon-weixin-active.png") 0 0 no-repeat;
  background-size: 100%;
}
