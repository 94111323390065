.input-color-primary {
  .ant-input-number-input {
    color: #37aeb9 !important;
  }
}

.input-color-gray {
  .ant-input-number-input {
    color: #999 !important;
  }
}

.tabs-schedule-sheet {
  .ant-tabs-nav::before {
    display: none;
  }

  .ant-tabs-tab {
    background: #fff !important;
  }

  .ant-tabs-tab-active {
    background: #37aeb9 !important;

    .ant-tabs-tab-btn {
      color: #fff !important;
    }
  }
}

.tabs-full-screen {
  height: 100%;

  .ant-tabs-content {
    height: 100%;

    .ant-tabs-tabpane {
      height: 100%;
    }
  }
}

.table-selector-ghost {
  .ant-select-selector {
    border: 0 !important;
    box-shadow: none !important;
    background: transparent !important;
  }
}

.table-multi-line-green {
  .ant-table-thead {
    .ant-table-cell {
      background: #92dab240 !important;
    }
  }

  .ant-table-tbody {
    .ant-table-row:nth-child(odd) {
      .ant-table-cell {
        background: #e3f9e933 !important;
      }
    }
  }
}

.table-a4-split {
  .ant-table-bordered {
    .ant-table-container {
      border-left: 0 !important;

      table {
        border-left: 1px solid #f0f0f0 !important;
      }
    }
  }
}

.select-login-style {
  .ant-select-selector {
    border: 0 !important;
    background: #f4f4f4 !important;
  }
}

.select-dropdown-wrap {
  .ant-select-item-option-content {
    white-space: pre-wrap !important;
  }
}

.modal-content-no-padding {
  &.ant-modal-wrap .ant-modal-content {
    padding: 0;
  }
}

.principal-comment-tips-modal {
  &.ant-modal-wrap .ant-modal-content {
    padding: 0;
    border-radius: 26px;
  }
}

.modal-radius-16 {
  .ant-modal-content {
    border-radius: 16px !important;
    overflow: hidden;
  }
}

.modal-header-no-margin {
  .ant-modal-header {
    margin: 0 !important;
  }
}

.modal-bg-gradient {
  &.ant-modal-wrap .ant-modal-content {
    background: linear-gradient(180deg, #d9fcff 0%, #fff 31.16%);
    border-radius: 16px;
  }
}

.modal-bg-gradient-light {
  &.ant-modal-wrap .ant-modal-content {
    background: linear-gradient(0deg, rgb(255 255 255 / 50%), rgb(255 255 255 / 50%)),
      linear-gradient(180deg, #d9fcff 0%, #fff 31.16%);
    border-radius: 16px;
  }
}

.modal-content-radius-16 {
  &.ant-modal-wrap .ant-modal-content {
    border-radius: 16px !important;
  }
}

.modal-content-ghost {
  &.ant-modal-wrap .ant-modal-content {
    background: transparent;
    box-shadow: none;
  }
}

.form-label-16 label {
  font-size: 16px !important;
}

.modal-bg-gradient-2 {
  &.ant-modal-wrap .ant-modal-content {
    background: linear-gradient(180deg, rgb(0 229 238 / 30%) 0%, #fff 40%), #fff;
    border-radius: 16px;
  }
}

.radio-group-button-round {
  background: #f7f8fa;
  border-radius: 200px;

  .ant-radio-button-wrapper {
    border-radius: 200px !important;
    color: #4e5969 !important;
    border: 0 !important;
    background: #f7f8fa !important;

    &::before {
      display: none !important;
    }

    &:hover {
      color: #37aeb9 !important;
    }
  }

  .ant-radio-button-wrapper-checked {
    background: #def8f6 !important;
    color: #37aeb9 !important;
  }
}

.input-text-align-center {
  input {
    text-align: center !important;
  }
}

// .ant-tooltip .ant-tooltip-inner {
//   background-color: #00b1bb !important;
// }

.input-ghost {
  background: transparent !important;
  box-shadow: none !important;
}

.table-last-line-bold {
  .ant-table-tbody {
    .ant-table-row:last-child {
      font-weight: bold;
    }
  }
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #5bc2c7;
  border-color: #5bc2c7;
}

.ant-modal-confirm-btns {
  .ant-btn-primary {
    background-color: #37aeb9;
  }

  .ant-btn-primary:not(:disabled):not(.ant-btn-disabled) {
    &:hover {
      background-color: #5bc2c7;
    }
  }
}

.table-cell-hover-outline {
  padding: 2px 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    outline: 1px solid #ddd;
  }
}

.ant-segmented {
  background-color: #f7f8fa !important;
  border-radius: 14px !important;
  margin-bottom: 0 !important;

  .ant-segmented-item {
    border-radius: 14px !important;

    .ant-segmented-item-label {
      padding: 0 16px;
      color: #4e5969 !important;
      font-weight: 400 !important;
    }
  }

  .ant-segmented-item-selected {
    background-color: #dcfdfb !important;

    .ant-segmented-item-label {
      color: #37aeb9 !important;
      font-weight: 400 !important;
    }
  }
}

.tooltip-bg-primary {
  .ant-tooltip-content {
    --antd-arrow-background-color: #00B1BB;
  }

  .ant-tooltip-inner {
    background: #00B1BB !important;
  }
}
