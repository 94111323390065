// 表格奇数行背景灰色
.table-row-odd-bg-gray {
  tr:nth-child(odd) {
    border-bottom: 0;
    background: #f7f7f7;
  }

  tr td {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
}

// 表格数据居中
.table-cell-align-center {
  tr td,
  th {
    vertical-align: middle;
  }
}

// 表格编辑样式
.table-editable-cell-value-wrap {
  min-height: 32px;
  padding: 5px 12px;
  cursor: pointer;

  &:hover {
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 4px 12px;
  }
}

// 表格非编辑样式
.table-cell-value-wrap {
  min-height: 32px;
  padding: 5px 12px;
}

// 表格行active主色
.table-row-active {
  background-color: #bdeaee !important;

  .ant-table-cell-row-hover {
    background-color: #bdeaee !important;
  }
}

// 表格footer背景白色
.table-footer-white {
  .ant-table-footer {
    background-color: white !important;
  }
}

// 取消表格hover样式
.table-cancel-hover-style {
  .ant-table-cell-row-hover {
    background: none !important;
  }
}

.table-body-cell-padding-mini {
  .ant-table-tbody {
    .ant-table-cell {
      padding: 4px !important;
    }
  }
}

.table-body-cell-no-padding {
  .ant-table-tbody {
    .ant-table-cell {
      padding: 0 !important;
    }
  }
}

// 修复表格最后行border消失
.table-last-cell-fill-border {
  border-bottom: 1px solid #0505050f !important;

  tr:last-child {
    td {
      //border-bottom: 0 !important;
    }
  }
}
